
























/**
 * @Author mcastrucci
 * Create biomarker result Modal.
 * This modal contains the BioamrkerResult form. It should be used when creating a new biomarker result
 * in the flight without changing the current view as a Modal.
 */
import { Component, Prop, Watch } from 'vue-property-decorator';
import DataPicker from '@/components/DataPicker.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { IListItem } from '@/interfaces/listItem';
import BiomarkerResultForm from '@/components/forms/BiomarkerResultForm.vue';


@Component({ components: { DataPicker, BiomarkerResultForm } })
export default class CreateBiomarkerResultModal extends AppComponent {
  @Prop({ required: true, type: Array }) public availableBiomarkers: IListItem [];

  public dialog = false;

  @Watch('dialog')
  public onDialogChange(newVal: boolean) {
      this.$bus.$emit('biomarker-results-form:reset');
  }

  public emitCreation(result) {
    this.$emit('biomarker-results:created', result);
    this.dialog = false;
  }

  public cancel() {
    this.dialog = false;
  }

}
