

















































import {IDocumentUpload} from '@/interfaces/documents';
import AppComponent from '@/mixins/ComponentMixin.vue';
import {dispatchUploadDocument} from '@/store/crud/actions';
import {Component, Prop} from 'vue-property-decorator';
import {IVisitDocument} from '@/interfaces/visits';

@Component({})
export default class UploadDocumentModal extends AppComponent {
  @Prop({default: () => []}) public documents: IVisitDocument[];
  @Prop({type: String, required: false, default: ''}) public patientId: string;
  @Prop({type: String, required: false, default: ''}) public requisitionId: string;
  @Prop({type: String, required: false, default: ''}) public visitId: string;
  // if everything is set from the parent component
  @Prop({type: Boolean, required: false, default: true}) public disabledFields: string;

  rules = [(value) => this.checkDocumentName(value)];

  public dialog = false;
  public pdfDocument: File | null = null;
  public docStatus = 'preliminary';
  public docStatuses = [
    {text: 'Preliminary', value: 'preliminary'},
    {
      text: 'Final',
      value: 'final',
    },
    {text: 'Corrected', value: 'corrected'},
  ];
  public valid = false;
  public disabled = false;
  public uploading = false;

  public get showRequisitionField() {
    return this.patientId && this.patientId !== '';
  }

  public get showVisitsField() {
    return this.requisitionId && this.requisitionId !== '';
  }

  public checkDocumentName(value) {
    let hasFileName = false;
    if (value && value.name) {
      hasFileName = this.documents.some((document) => {
        const documentSplit = document.url.split('/');
        const documentName = documentSplit[3];
        return documentName.toLowerCase() === value.name.toLowerCase();
      });
    }
    this.disabled = hasFileName;
    return hasFileName ? 'Document with this name already exists' : true;
  }

  public fileUploaded(file) {
    this.pdfDocument = file;
  }

  public async onSubmit() {
    // transform the name of the file to lower case
    // create a new file
    if (!this.pdfDocument) {
      this.toast('Please select a file to upload', true);
      return;
    }
    const file = new File([this.pdfDocument], this.pdfDocument.name, {
      type: this.pdfDocument.type,
    });
    try {
      const requestPayload: IDocumentUpload = {
        patient_id: this.patientId,
        requisition_id: this.requisitionId,
        visit_id: this.visitId,
        pdf_file_name: file as File,
      };
      this.uploading = true;
      const result = await dispatchUploadDocument(this.$store, requestPayload);
      if (result && result.data) {
        this.dialog = false;
        await this.$nextTick();
        this.uploading = false;
        this.$emit('document-uploaded', {
          url: result.data.url,
          docStatus: this.docStatus,
          docType: 'DiagnosticReport',
          visible: this.docStatus === 'final',
        });
      }
    } catch (error) {
      this.toast('Failed while uploading document', true);
      // FIXME - important log here
    }

    if (this.$refs.fileInput) {
      // @ts-ignore
      this.$refs.fileInput.reset();
    }
  }
}
