











































/**
 * Short biomarker results table used for the create / edit visit view
 * This shows the biomarker results that are included into the visit
 */
import { Component, Prop } from 'vue-property-decorator';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { IListItem } from '@/interfaces/listItem';
import CreateBiomarkerResultModal from '@/components/modals/CreateBiomarkerResultModal.vue';
import EditBiomarkerResultModal from '@/components/modals/EditBiomarkerResultModal.vue';
import { IBiomarker } from '@/interfaces/biomarkers';


@Component({ components: { ConfirmationModal, CreateBiomarkerResultModal, EditBiomarkerResultModal } })
export default class BiomarkerResultsTable extends AppComponent {
  @Prop({ type: Array }) public items: IListItem;
  @Prop({ type: Array }) public availableBiomarkers: IBiomarker[];

  public showMenu = false; // util for v-menu to handle click outside

  public search = ''; // used for search in data table
  public headers = [
    {
      text: 'Biomarker',
      sortable: true,
      value: 'biomarker',
      align: 'left',
    },
    {
      text: 'Result',
      sortable: true,
      value: 'result',
      align: 'center',
    },
    {
      text: 'Actions',
      value: 'id',
      align: 'right',
    },
  ];

  public async deleteBiomarkerResult(biomarkerResult) {
    this.$emit('delete:biomarker-result', biomarkerResult);
  }

  public async biomarkerResultUpdated(biomarkerResult, id) {
    this.$emit('biomarker-result:updated', biomarkerResult, id);
  }
}
