































































































import {Component, Prop} from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import {ITableRequisitionDocuments} from '@/interfaces/documents';
import UploadDocumentModal from '@/components/modals/UploadDocumentModal.vue';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import {IVisitDocument} from '@/interfaces/visits';

@Component({components: {UploadDocumentModal, ConfirmationModal}})
export default class DocumentsTable extends AppComponent {
  @Prop({default: true}) public showActions: boolean;
  @Prop({default: true}) public showSearch: boolean;
  @Prop({default: false}) public yieldActions: boolean;
  @Prop({default: 10}) public itemsPerPage: number;
  @Prop({default: () => []}) public documents: IVisitDocument[];
  @Prop({default: false}) public tableLoading: boolean;
  @Prop({default: false}) public changingVisibility: boolean;

  public search = '';

  public headers = [
    {
      text: 'Document Name',
      sortable: true,
      value: 'url',
      align: 'left',
    },
    {
      text: 'status',
      sortable: true,
      value: 'docStatus',
      align: 'center',
    },
    {
      text: 'Show in members portal',
      sortable: true,
      value: 'visible',
      align: 'center',
    },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
    },
  ];

  public existingDocuments: ITableRequisitionDocuments[] = [];

  public documentIsValid(status: string) {
    return !status.includes('invalid');
  }

  /**
   * Creates a new window and displays the document
   */
  public forceNewWindow(response) {
    const file = new Blob([response.data], {type: 'application/pdf'});
    const url = window.URL.createObjectURL(file);
    window.open(url);
  }

  public deleteDocument(document: IVisitDocument) {
    this.$emit('delete-document', document);
  }

  public changeDocumentVisibility(document: IVisitDocument) {
    this.$emit('change-visibility', document, !document.visible);
  }
}
