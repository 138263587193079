























/**
 * @Author mcastrucci
 * Create biomarker result Modal.
 * This modal contains the BioamrkerResult form. It should be used when creating a new biomarker result
 * in the flight without changing the current view as a Modal.
 */
import { Component, Prop, Watch } from 'vue-property-decorator';
import DataPicker from '@/components/DataPicker.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { IListItem } from '@/interfaces/listItem';
import BiomarkerResultForm from '@/components/forms/BiomarkerResultForm.vue';


@Component({ components: { DataPicker, BiomarkerResultForm } })
export default class EditBiomarkerResultModal extends AppComponent {
  @Prop({ required: true, type: Array }) public availableBiomarkers: IListItem [];
  @Prop({ type: String }) public biomarkerResultId: string;


  public show = false;

  @Watch('show')
  public async onDialogChange(newVal: boolean, oldVal: boolean) {
    if (newVal !== oldVal) {
        await this.$nextTick(); // thread race, we give the biomarkers result form time to be created
        await this.$nextTick();
        this.$bus.$emit('biomarker-results-form:reset');
    }
  }

  public emitUpdate(result) {
    this.$emit('biomarker-results:updated', result, this.biomarkerResultId);
    this.show = false;
  }

  public cancel() {
    this.show = false;
  }

}
