

































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ListComponent extends Vue {
@Prop({ type: Array }) public elementList: string[];
@Prop({ type: Boolean, default: false }) public textArea: boolean;
@Prop({ type: String }) public label: string;

    public menu = false;

    get list() {
        return this.elementList;
    }

    get inputLabel() {
        return this.label;
    }

    get fieldType() {
        return !this.textArea;
    }

}
