



























































import { Component, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import AppComponent from '@/mixins/ComponentMixin.vue';

@Component
export default class DateTimePicker extends AppComponent {
@Prop({ type: String }) public dateProp: string;
@Prop({ type: String }) public dataPickerLabel: string;
@Prop({ type: String }) public timezone: string;
@Prop({ type: Boolean }) public disable: boolean;

    public menu = false;

    public dateSelected: string | null = null;
    public dateSelectedTime: string | null = null;
    public datePropFormatted: string | null = null;

    public resetDate() {
        this.dateSelected = null;
        this.dateSelectedTime = null;
    }

    get submitEnabled() {
        return this.dateSelected && this.dateSelectedTime;
    }

    public mounted() {
        this.datePropFormatted = this.dateProp;
    }

    public cancel() {
        this.resetDate();
        this.menu = false;
    }

    public saveDate() {
        // to utc time
        const utcDate = this.convertLocalTimeToUtc(
            this.dateSelected + ' ' + this.dateSelectedTime,
            this.timezone,
        );

        this.$emit('dateChanged', utcDate);
        this.resetDate();
        this.menu = false;
    }

    @Watch('timezone')
    public onTimezoneChanged() {
        this.setFormattedDateProp();
    }

    @Watch('dateProp')
    public onDatePropChanged(newValue) {
        this.setFormattedDateProp();
    }

    public setFormattedDateProp() {
        if (this.dateProp && this.dateProp.length > 0 && this.timezone && this.timezone.length > 0) {
            this.datePropFormatted =  this.getFormattedDateTimeFromUtc(this.dateProp, this.timezone);
        } else if (this.dateProp && this.dateProp.length > 0) {
            this.datePropFormatted = this.getFormattedDateTimeFromUtc(this.dateProp, 'UTC');
        } else {
            this.datePropFormatted = '';
        }
    }

    @Watch('menu')
    public onMenuChange(newVal: boolean, oldVal: boolean) {
        let date = '';
        if (newVal && this.dateProp && this.dateProp.length > 0) {
            date = this.convertUtcToLocalTime(this.dateProp, this.timezone);
        } else {
            date = this.convertUtcToLocalTime(moment().utc().toString(), this.timezone);
        }
        const onlyDate = moment(date).format('YYYY-MM-DD');
        const onlyTime = moment(date).format('HH:mm');
        this.dateSelectedTime = onlyTime;
        this.dateSelected = onlyDate;
    }
}
