var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isAppLoading)?_c('div',[_c('v-card',[_c('v-card-title',[(_vm.showSearch)?_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),(_vm.tableLoading || _vm.changingVisibility)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"items-per-page":_vm.itemsPerPage,"items":_vm.documents,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.url",fn:function(props){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(props.item.url.split("/").pop())+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(props.item.url.split("/").pop()))])])]}},{key:"item.docStatus",fn:function(props){return [_c('span',{style:(!_vm.documentIsValid(props.item.docStatus) ? 'color: red;' : '')},[_vm._v(_vm._s(props.item.docStatus))])]}},{key:"item.actions",fn:function(props){return [(_vm.showActions)?_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(_vm.documentIsValid(props.item.docStatus))?[_c('ConfirmationModal',{attrs:{"listItem":"","buttonLabel":"Change Document Visibility","modalWidth":450,"title":"Change document Visibility","showDeleteWarning":false,"iconColor":'black',"icon":"change_circle","confirmationLabel1":"Do you want to change the document visibility? ","confirmationLabel2":((props.item.visible
                        ? 'This document will be visible to the patient in the members portal'
                        : 'This document will not longer visible for the patient in the members portal') + ".")},on:{"confirm":function($event){return _vm.changeDocumentVisibility(props.item)}}}),_c('ConfirmationModal',{attrs:{"listItem":"","buttonLabel":"Delete Document","modalWidth":450,"title":"Delete document from Bucket","showDeleteWarning":true,"iconColor":'red',"icon":"delete","confirmationLabel1":"Do you want to remove the document from the bucket?","confirmationLabel2":("" + (props.item.url.split('/').pop()))},on:{"confirm":function($event){return _vm.deleteDocument(props.item)}}})]:_vm._e()],2)],1)],1):_vm._e()]}}],null,false,1767758093)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }