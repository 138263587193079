









































































































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import DateTimePicker from '@/components/DateTimePicker.vue';
import {requisitionEditTypes} from '@/constants/globalConstants';
import {getCitiesOfState} from '@/constants/usCities.js';
import ListComponent from '@/components/ListComponent.vue';

import {
  dispatchCreateBiomarkerResult,
  dispatchCreateCriticalResult,
  dispatchCreateVisitDocuments,
  dispatchDeleteBiomarkerResult,
  dispatchDeleteDocument,
  dispatchDeleteVisitDocuments,
  dispatchGetPackages,
  dispatchGetPatientsForList,
  dispatchGetTimezone,
  dispatchGetVisitById,
  dispatchGetVisitDocuments,
  dispatchHideVisitDocuments,
  dispatchShowVisitDocuments,
  dispatchUpdateBiomarkerResult,
  dispatchUpdateCriticalResult,
  dispatchUpdateVisit,
} from '@/store/crud/actions';
import AppComponent from '@/mixins/ComponentMixin.vue';
import {ICreateVisit, IUpdateVisit, IVisit, IVisitDocument} from '@/interfaces/visits';
import {IRequisition} from '@/interfaces/requisitions';
import {IPatient} from '@/interfaces/patients';
import {IPackage} from '@/interfaces/packages';
import {
  IBiomarkerResult,
  ICreateBiomarkerResult,
  IUpdateBiomarkerResult,
} from '@/interfaces/biomarkerResults';
import CreateBiomarkerResultModal from '@/components/modals/CreateBiomarkerResultModal.vue';
import CreateGrailResultModal from '@/components/modals/CreateGrailResultModal.vue';

import BiomarkerResultsTable from '@/components/tables/BiomarkerResultsTable.vue';
import DocumentsTable from '@/components/tables/DocumentsTable.vue';
import {biomarkerResultExpandedMapConfig, biomarkersMapConfig} from '@/constants/mapConfigs';
import {IBiomarker} from '@/interfaces/biomarkers';
import {IPanel} from '@/interfaces/panels';
import {IListItem} from '@/interfaces/listItem';
import {readOnePackage} from '@/store/crud/getters';
import UploadDocumentModal from '../modals/UploadDocumentModal.vue';
import {ICriticalResultCreate} from '@/interfaces/criticals';
import DocumentsSimpleTable from '@/components/tables/DocumentsSimpleTable.vue';

@Component({
  components: {
    DocumentsSimpleTable,
    DateTimePicker,
    CreateBiomarkerResultModal,
    BiomarkerResultsTable,
    CreateGrailResultModal,
    UploadDocumentModal,
    ListComponent,
    DocumentsTable,
  },
})
export default class AdminVisitForm extends AppComponent {
  @Prop({type: Object, required: false}) public requisition: IRequisition;
  @Prop({type: Object, required: false}) public requisitionVisit: IVisit;
  @Prop({type: String, required: false}) public visitId: string;

  public visitDate: string = '';
  public streetAddress: string = '';
  public visitTimezone: string | null = null;
  public city: string = '';
  public state: any = '';
  public zipCode: string = '';
  public selectedPackages: string[] = [];
  public expandedBiomarkers: IBiomarker[] = []; // stores the expandaded biomarkers information
  public assignedBiomarkerResults: any = [];
  public filteredBiomarkerResultsList: any = []; // biomarker result list processed for display in the table
  public digestedBiomarkerResultsList: any = []; // stores the biomarker result list after digesting it
  public resultsAvailable: boolean = false;
  public visitBiomarkers: IBiomarker[] = []; // stores the biomarkers assigned to the visit
  public confirmationCode: string = '';
  public visitDocumentsDigest: IVisitDocument[] = []; // list of documents processed for display in the table
  public bucketDocumentDigest: IVisitDocument[] = []; // list of documents in bucket but not storage in visit documents
  public criticalResultReviewed: boolean | null | undefined = false;
  public visitHasCritical: boolean = false;
  public criticalLevel: string = '2';
  public criticalLevels: string[] = ['1', '2'];
  public assignedPatient: string = '';
  public loading: boolean = false;
  public patientProfile: any = {};
  public requisitionEditTypes = requisitionEditTypes;
  public mode = requisitionEditTypes.CREATION;
  public bucketDocuments: any = [];
  public extraListOfCities: IListItem[] = [];
  public listOfCitiesForState: IListItem[] = [];
  public selectedCity: string = '';

  // grail results
  public grailTestPackageSelected: boolean = false;

  public requestingTimezone: boolean = false;

  public visit: IVisit | null = null;
  public openedPanels = 1;
  public documentsTableLoading: boolean = false;
  public changingDocumentVisibility: boolean = false;

  public mounted() {
    this.mode = this.getOperationType();
    this.configureView();
  }

  /**
   * Defines an operation type based
   * on the Props that were passed to the component.
   */
  public getOperationType() {
    let mode = '';
    if (!this.visitId && this.requisition && Object.keys(this.requisition).length > 0) {
      if (this.requisitionVisit && Object.keys(this.requisitionVisit).length > 0) {
        mode = this.requisitionEditTypes.EDIT_FROM_REQUISITION;
      } else {
        mode = this.requisitionEditTypes.CREATION;
      }
    } else if (this.visitId && this.visitId.length > 0) {
      mode = this.requisitionEditTypes.EDIT_FROM_VISIT;
    } else {
      // FIXME - important log this error
      // no correct option for the view, returning to main page
      this.$router.push('/');
    }
    return mode;
  }

  public get isEdit() {
    return (
      this.mode === this.requisitionEditTypes.EDIT_FROM_REQUISITION ||
      this.mode === this.requisitionEditTypes.EDIT_FROM_VISIT
    );
  }

  /**
   * Configures the view
   * and checks that the correct data is available according to the mode
   */
  public async configureView() {
    this.setAppLoading(true);
    await this.fetchData();
    // charge the needed data from the requisitions object if it is passed in
    if (this.mode === this.requisitionEditTypes.CREATION) {
      if (!this.requisition.patient || Object.keys(this.requisition.patient).length === 0) {
        // FIXME - important log this error
        // no correct option for the view, returning to main page
        this.toast('No patient found in the requisition', true);
        this.$router.push('/');
      }
    } else if (this.mode === this.requisitionEditTypes.EDIT_FROM_REQUISITION) {
      if (
        !this.requisitionVisit ||
        Object.keys(this.requisitionVisit).length === 0 ||
        !this.requisition ||
        Object.keys(this.requisition).length === 0 ||
        !this.requisition.patient ||
        Object.keys(this.requisition.patient).length === 0
      ) {
        // FIXME - important log this error
        // no correct option for the view, returning to main page
        this.toast('Unable to edit visit', true);
        this.$router.push('/');
      }
    }

    this.reset();
    this.digestDocuments(); //Deprecate after Doc visibility migration rollout
    this.setAppLoading(false);
  }

  /**
   * Fetches data from server
   */
  public async fetchData() {
    await dispatchGetPatientsForList(this.$store);
    await dispatchGetPackages(this.$store); // full packages data

    // if it is a visit edit, we fetch the visit data from its id
    if (this.mode === this.requisitionEditTypes.EDIT_FROM_VISIT && this.visitId) {
      const visit = await dispatchGetVisitById(this.$store, this.visitId);

      if (visit && Object.keys(visit).length > 0) {
        this.state = visit.state;
        this.city = visit.city;
        this.visit = visit as IVisit;
        this.visit.city = this.city;
        this.patientProfile = this.visit.patient;
        this.assignedPatient = this.patientProfile.id || '';
        this.criticalResultReviewed = this.visit.criticalResult?.criticalReviewed;

        this.bucketDocuments = await dispatchGetVisitDocuments(this.$store, {
          yieldMessages: true,
          visitId: this.visitId,
          patientId: this.assignedPatient,
        });

        this.visitDocumentsDigest = visit.visitDocuments || [];
      } else {
        this.toast('Failed while fetching the visit', true);
      }
    }
  }

  /*
   * If city is not in the list try to get by approximation
   * if not found, add it to the list of cities given the state
   */
  public buildCitiesList() {
    const sanitizeToCompare = (str) => str?.toLowerCase().replace(/\s/g, '');
    const visitObj =
      this.city &&
      this.state &&
      getCitiesOfState(this.state).find((c) =>
        sanitizeToCompare(c.n).includes(sanitizeToCompare(this.city)),
      );
    if (!visitObj) {
      console.info(`City ${this.city} not found in the list, added it to the list`);
      this.extraListOfCities.push({text: this.city, value: this.city});
    }
  }

  /*
    It resets the form based on the mode (Creation, edit or edit from requisition)
  */
  public reset() {
    if (this.mode === this.requisitionEditTypes.CREATION) {
      const patient = this.requisition.patient as IPatient;
      this.assignedPatient = patient.id || '';
      this.patientProfile = patient;
      this.state = this.requisition.requisitionState || '';
      this.visitDate = '';
      this.streetAddress = '';
      this.resultsAvailable = false;
      this.criticalLevel = '';
      this.city = '';
      this.zipCode = '';
      this.confirmationCode = '';
      this.selectedPackages = [];
      this.visitHasCritical = false;
    } else if (this.mode === this.requisitionEditTypes.EDIT_FROM_REQUISITION) {
      const visit = this.requisitionVisit as IVisit;
      this.visit = visit;
      const patient = this.requisition.patient as IPatient;
      this.assignedPatient = patient.id || '';
      this.patientProfile = patient;
      this.resultsAvailable = visit.resultsAvailable || false;
      this.streetAddress = visit.streetAddress || '';
      this.confirmationCode = visit.confirmationCode || '';
      this.state = visit.state || '';
      this.city = visit.city || '';
      this.visitDate = visit.visitDate ? visit.visitDate.toString() : '';
      this.criticalLevel = visit.criticalResult?.level || '';
      this.zipCode = visit.zip || '';
      this.visitHasCritical = !!visit.criticalResult;
      this.selectedPackages =
        visit.packages && visit.packages.length > 0
          ? this.getPackagesIdsFromPackages(visit.packages as IPackage[])
          : [];
      this.assignedBiomarkerResults =
        visit.biomarkerResults && visit.biomarkerResults.length > 0
          ? (visit.biomarkerResults as IBiomarkerResult[])
          : [];
      this.criticalResultReviewed = Boolean(visit.criticalResult?.criticalReviewed);
    } else if (this.mode === this.requisitionEditTypes.EDIT_FROM_VISIT) {
      const visit = this.visit as IVisit;
      this.streetAddress = visit.streetAddress || '';
      this.confirmationCode = visit.confirmationCode || '';
      this.resultsAvailable = visit.resultsAvailable || false;
      this.state = visit.state || '';
      this.city = visit.city || '';
      this.visitDate = visit.visitDate ? visit.visitDate.toString() : '';
      this.criticalLevel = visit.criticalResult?.level || '';
      this.visitHasCritical = !!visit.criticalResult;
      this.criticalResultReviewed = Boolean(visit.criticalResult?.criticalReviewed);
      this.zipCode = visit.zip || '';
      this.selectedPackages =
        visit.packages && visit.packages.length > 0
          ? this.getPackagesIdsFromPackages(visit.packages as IPackage[])
          : [];
      this.assignedBiomarkerResults =
        visit.biomarkerResults && visit.biomarkerResults.length > 0
          ? (visit.biomarkerResults as IBiomarkerResult[])
          : [];
    }
    this.listOfCitiesForState = this.getCitiesForState();
    // Add add-on test here
    this.$validator.reset();
  }

  /**
   * It creates a visit Update payload
   * and submits it to the server.
   * An visit-update event is emitted after the server response.
   * so the parent container can handle the response.
   */
  public async updateVisit(silent = false) {
    if ((await this.$validator.validateAll()) && this.visit && this.visit.id) {
      const updatePayload: IUpdateVisit = {};

      if (this.visitDate && this.visitDate.length > 0) {
        updatePayload.visitDate = this.visitDate;
      }
      this.setFieldIfChanges(
        updatePayload,
        'streetAddress',
        this.streetAddress,
        this.visit.streetAddress,
      );
      this.setFieldIfChanges(updatePayload, 'city', this.city, this.visit.city);
      this.setFieldIfChanges(updatePayload, 'state', this.state, this.visit.state);
      this.setFieldIfChanges(updatePayload, 'zip', this.zipCode, this.visit.zip);
      this.setFieldIfChanges(
        updatePayload,
        'resultsAvailable',
        this.resultsAvailable,
        this.visit.resultsAvailable,
      );
      this.setFieldIfChanges(
        updatePayload,
        'confirmationCode',
        this.confirmationCode,
        this.visit.confirmationCode,
      );
      if (
        this.isListDifferent(
          this.selectedPackages || [],
          this.visit.packages
            ? this.getPackagesIdsFromPackages(this.visit.packages as IPackage[])
            : [],
        )
      ) {
        updatePayload.packages = [] as string[];
        this.selectedPackages.forEach((p) => {
          updatePayload.packages!.push(p);
        });
      }

      if (Object.keys(updatePayload).length > 0) {
        const result = await dispatchUpdateVisit(this.$store, {
          visitId: this.visit.id,
          visit: updatePayload,
        });
        if (result) {
          if (!silent) {
            this.toast('Visit updated successfully');
            this.$emit('visit-updated', result);
          }
        } else {
          this.toast('Error while updating visit', true);
        }
      }
    }
  }

  public async createCritical() {
    const payload: ICriticalResultCreate = {
      visitId: this.visitId,
      level: '1',
      criticalReviewed: false,
    };
    try {
      const critical = await dispatchCreateCriticalResult(this.$store, payload);
      this.toast('Critical result created successfully');
      this.visitHasCritical = true;
      // add the critical result to the visit
      if (this.visit && this.visit.id) {
        this.visit.criticalResult = critical;
        this.criticalLevel = critical.level;
      }
    } catch (e) {
      this.toast('Error while creating critical result', true);
      this.visitHasCritical = false;
    }
  }

  public async changeCriticalLevel() {
    if (this.visit !== null) {
      if (this.visit.id && this.visit.criticalResult) {
        this.setAppLoading(true);
        const result = await dispatchUpdateCriticalResult(this.$store, {
          id: this.visit.criticalResult.id,
          level: this.criticalLevel,
        });

        this.setAppLoading(false);

        if (result) {
          this.toast('Critical results level saved!', false);
        } else {
          this.toast('error saving critical results level', true);
        }
      }
    } else {
      return;
    }
  }

  public async deleteDocument(document: IVisitDocument) {
      this.documentsTableLoading = true;
      this.$nextTick(async () => {
        const result = await dispatchDeleteVisitDocuments(this.$store, {
          visitDocumentId: document.id as string,
        });
        if (result) {
          this.visitDocumentsDigest = this.visitDocumentsDigest.filter((d) => d.id !== document.id);

          const { deleted, id, ...restDoc } = document;
          this.bucketDocumentDigest.push(restDoc);
          this.toast('Document deleted successfully');
        } else {
          this.toast('Error while deleting document', true);
        }
        this.documentsTableLoading = false;
      });
  }

  /**
   * When a document is uploaded, include it into the digested list
   * and also if the document is final, make an update to the visit
   * to include the final document
   */
  public async documentUploaded(document: IVisitDocument) {
    this.documentsTableLoading = true;
    //@ts-ignore
    const { __typename, ...restDoc } = document;
    this.$nextTick(async () => {
      const result = await dispatchCreateVisitDocuments(this.$store, {
        ...restDoc,
        visitId: this.visitId,
      });
      if (result) {
        this.bucketDocumentDigest = this.bucketDocumentDigest.filter((d) => d.url !== document.url);

        this.visitDocumentsDigest.push(result);
        this.toast('Document uploaded successfully');
      } else {
        this.toast('Error while uploading document', true);
      }
      this.documentsTableLoading = false;
    });
  }

  public selectedVisitChanged(newCity: string) {
    this.city = newCity;
  }

  /**
   * Changes if a document should be shown in members-app or not
   * by updating the list of documents in the visit
   */
  public async changeDocumentVisibility(document: IVisitDocument, visible: boolean) {
    this.documentsTableLoading = true;
    const toggleDispatch = visible ? dispatchShowVisitDocuments : dispatchHideVisitDocuments;
    this.$nextTick(async () => {
      const result = await toggleDispatch(this.$store, {visitDocumentId: document.id as string});
      if (result) {
        this.visitDocumentsDigest[
          this.visitDocumentsDigest.findIndex((el) => el.id === document.id)
        ].visible = visible;
        this.toast('Document now is visible in member app');
      } else {
        this.toast('Error while uploading document', true);
      }
      this.documentsTableLoading = false;
    });
  }

  /**
   * When the visitDocuments raw list changes
   * digest the data to make it usable by the document table component
   */
  public digestDocuments() {
    for (const doc of this.bucketDocuments) {
      if (this.visitDocumentsDigest.some((el) => el.url.toLowerCase() === doc.toLowerCase()))
        continue; // check for document consistency
      const documentSplit = doc.split('/');
      if (documentSplit.length < 4) {
        this.bucketDocumentDigest.push({
          visible: false,
          docStatus: 'invalid',
          url: doc.toString(),
        });
        continue; // invalid document
      }
      const visitId = documentSplit[2];
      const documentName = documentSplit[3];
      let documentStatus: IVisitDocument['docStatus'];
      switch (documentName) {
        case documentName.includes('final'):
          documentStatus = 'final';
          break;
        case documentName.includes('preliminary'):
          documentStatus = 'preliminary';
          break;
        case documentName.includes('corrected'):
          documentStatus = 'corrected';
          break;
        default:
          documentStatus = 'unknown';
          break;
      }
      this.bucketDocumentDigest.push({
        url: doc.toString(),
        visitId,
        visible: false,
        docStatus: documentStatus,
      });
    }
  }

  /**
   * Handles the form submission
   * It checks if the mode is creation or edition.
   * If it is creation, the parent component will handle it.
   * If it is edition, it updates the visit. and notifies the parent component.
   */
  public async submit(silent = false) {
    if (await this.$validator.validateAll()) {
      const payload = {
        patientId: this.assignedPatient,
        visitDate: this.visitDate,
        streetAddress: this.streetAddress,
        confirmationCode: this.confirmationCode,
        city: this.city,
        state: this.state,
        zip: this.zipCode,
        packages: this.selectedPackages,
      };

      if (
        this.mode === this.requisitionEditTypes.EDIT_FROM_VISIT ||
        this.mode === this.requisitionEditTypes.EDIT_FROM_REQUISITION
      ) {
        this.updateVisit(silent);
      } else {
        this.$emit('create-visit', payload as ICreateVisit);
      }
    }
  }

  public cancel() {
    if (!this.isEdit) {
      this.$router.back();
    } else {
      this.$emit('cancel-visit-edition');
    }
  }

  @Watch('requestingTimezone')
  public onTimezoneChange() {
    this.setAppLoading(this.requestingTimezone);
  }

  /**
   * On state change
   * reset the city, timezone and visit date
   */
  @Watch('state')
  public onStateChange(newVal, oldVal) {
    if (oldVal && oldVal.length > 0 && newVal !== oldVal) {
      this.city = '';
      this.selectedCity = '';
      this.extraListOfCities = [];
      this.listOfCitiesForState = this.getCitiesForState();
      this.visitTimezone = null;
      this.visitDate = '';
    }
  }

  @Watch('city')
  public async onCityChange() {
    if (this.city && this.state) {
      /*
        Check if the visit exist in the list of cities.
        if not, rebuild the list of cities including it
      */
      this.buildCitiesList();
      this.selectedCity = this.city;
      try {
        this.requestingTimezone = true;
        const timezone = await dispatchGetTimezone(this.$store, {
          city: this.city,
          state: this.state,
        });
        if (timezone && timezone.length > 0) {
          this.visitTimezone = timezone;
        }
      } catch (e) {
        // FIXME - important log here
        this.toast('Error while getting timezone', true);
        this.visitDate = '';
        this.city = '';
        this.state = '';
      } finally {
        this.requestingTimezone = false;
      }
    }
  }

  /**
   * Watches for changes in the assigned packages
   * and generates a subArray of biomarkers that will be available
   * in case of creating biomarker results
   */
  @Watch('selectedPackages')
  public onAssignedPackagesChanged(assignedPackages: string[]) {
    if (assignedPackages && assignedPackages.length > 0) {
      // get the actual packages from the Ids in assignedPackages
      const selectedPackages: IPackage[] = [];
      assignedPackages.forEach(async (id: string) => {
        const tempPackage = this.packages.find((p: IPackage) => p.id === id);
        if (tempPackage) {
          selectedPackages.push(tempPackage);
        }
      });
      // get an array of biomarker from the selected packages in their panels
      const biomarkers: IBiomarker[] = [];
      selectedPackages.forEach((p: IPackage) => {
        if (p.panels && p.panels.length > 0) {
          (p.panels as IPanel[]).forEach((panel: IPanel) => {
            if (panel.biomarkers && panel.biomarkers.length > 0) {
              biomarkers.push(...(panel.biomarkers as IBiomarker[]));
            }
          });
        }
      });

      // check if GRAIL test package was selected
      this.grailTestPackageSelected = this.checkGrailTestPackageSelected();

      // digest the biomarkers to show on the list
      this.digestData('visitBiomarkers', biomarkers, biomarkersMapConfig, 'id');
      this.expandedBiomarkers = biomarkers;
    } else {
      this.visitBiomarkers = [];
      this.grailTestPackageSelected = false;
    }
  }

  @Watch('assignedBiomarkerResults')
  public assignedBiomarkerResultsChanged(assignedBiomarkerResults: string) {
    if (assignedBiomarkerResults && assignedBiomarkerResults.length > 0) {
      // get the biomarker results from the list of Ids
      const tempBiomarkerResults: IBiomarkerResult[] = [];
      this.digestData(
        'digestedBiomarkerResultsList',
        assignedBiomarkerResults,
        biomarkerResultExpandedMapConfig,
        'id',
      );
    } else {
      this.digestedBiomarkerResultsList = [];
    }
  }

  // prepares the data to be displayed in the table
  @Watch('digestedBiomarkerResultsList')
  public digestedBiomarkerResultsListChanged(biomarkerResultList: IListItem[]) {
    if (biomarkerResultList && biomarkerResultList.length > 0) {
      this.filteredBiomarkerResultsList = [];
      biomarkerResultList.forEach((el) => {
        if (el && el.text && el.text.length > 0) {
          const temp = el.text.split(';');
          this.filteredBiomarkerResultsList.push({
            biomarker: temp[0],
            result: temp[1],
            id: el.value,
          });
        }
      });
    } else {
      this.filteredBiomarkerResultsList = [];
    }
  }

  get listOfCities() {
    return [...this.listOfCitiesForState, ...this.extraListOfCities];
  }

  public getCitiesForState() {
    if (this.state) {
      return getCitiesOfState(this.state).reduce((acc, c) => {
        acc.push({
          text: c.n,
          value: c.n,
        });
        return acc;
      }, [] as any[]);
    }
    return [];
  }

  /**
   * Checks if the create biomarker result section is visible
   */
  get displayCreateBiomarkerResultsSection() {
    let show = false;
    if (this.selectedPackages && this.selectedPackages.length > 0) {
      if (this.visitBiomarkers && this.visitBiomarkers.length > 0) {
        show = true;
      }
    }
    if (this.assignedBiomarkerResults && this.assignedBiomarkerResults.length > 0) {
      show = true;
    }
    return show;
  }

  /**
   * Checks if the grail package is selected
   */
  public checkGrailTestPackageSelected() {
    let selected = false;
    if (this.selectedPackages && this.selectedPackages.length > 0) {
      this.selectedPackages.forEach((p) => {
        const currentPackage = readOnePackage(this.$store)(p);
        if (currentPackage) {
          if (currentPackage.name && currentPackage.name.toLowerCase().includes('grail')) {
            selected = true;
          }
        }
      });
    }
    return selected;
  }

  /**
   * event handler for grailResult created from modal.
   * Creates a biomarker result for each one of the grail test result
   * @param biomarkerResults the biomarker results to be created and added to the visit.
   */
  public async grailResultsCreated(biomarkerResults: IBiomarkerResult[]) {
    // creates a biomarker result for each result in our array
    const creationResults: boolean[] = [];
    for (const result of biomarkerResults) {
      const creationResult = await this.biomarkerResultCreated(result, false, true);
      creationResults.push(creationResult);
    }
    // if any of the creation results failed then show an error message
    if (creationResults.includes(false)) {
      this.toast('Error while creating some of the biomarker results', true);
    } else {
      this.toast('Biomarker results created successfully');
    }
  }

  /**
   * Updates the biomarker result
   * removes the old one from the list and adds the new one in the same position
   */
  public async biomarkerResultUpdated(
    biomarkerResult: IUpdateBiomarkerResult,
    biomarkerResultId: string,
  ) {
    const result = await dispatchUpdateBiomarkerResult(this.$store, {
      biomarkerResultId,
      biomarkerResult,
    });

    if (result) {
      // remove biomarker result in the index and add the new one in the same index
      const index = this.assignedBiomarkerResults.findIndex((b) => b.id === biomarkerResultId);
      if (index >= 0) {
        this.assignedBiomarkerResults.splice(index, 1);
        this.assignedBiomarkerResults.splice(index, 0, result);
      }
    }
  }

  /**
   * creates a biomarker result
   * once biomarker result is created, we patch the current result with the new biomarkers Result content
   */
  public async biomarkerResultCreated(
    result: IBiomarkerResult,
    updateVisit = true,
    yieldMessages: boolean = false,
  ) {
    try {
      if (!this.visit || !this.visit.id) {
        throw new Error('Visit not found');
      }
      this.setAppLoading(true);
      if (updateVisit) {
        await this.submit(true);
      }
      // update visit changes before creating the biomarker result

      const payload: ICreateBiomarkerResult = result;
      const biomarkerFromResult = this.expandedBiomarkers.find((el) => {
        return el.id === result.biomarkerId;
      });
      // add name and questBiomarkerId to the biomarker result
      if (biomarkerFromResult) {
        payload.biomarkerName = biomarkerFromResult.name;
        payload.questBiomarkerId = biomarkerFromResult.questBiomarkerCode;
        payload.visitId = this.visit.id;

        // POST the new biomarker result to the server
        const creationResult = await dispatchCreateBiomarkerResult(this.$store, payload);

        if (creationResult) {
          this.assignedBiomarkerResults = [...this.assignedBiomarkerResults, creationResult];
          if (!yieldMessages) {
            this.toast('Biomarker result created');
          }
          return Promise.resolve(true);
        } else {
          throw new Error(
            'Failed while creating a biomarker result, server error - ' + creationResult,
          );
        }
      } else {
        throw new Error('[AdmminVisitForm] biomarker not found in the list of biomarkers');
      }
    } catch (error) {
      if (!yieldMessages) {
        this.toast('Error while creating biomarker result', true);
      }
      return Promise.resolve(false);
      // FIXME - important log this error
    } finally {
      this.setAppLoading(false);
    }
  }

  public async toggleCriticalResultReview() {
    if (this.visit !== null) {
      this.setAppLoading(true);

      if (this.visit.id && this.visit.criticalResult) {
        const result = await dispatchUpdateCriticalResult(this.$store, {
          id: this.visit.criticalResult.id,
          criticalReviewed: Boolean(this.criticalResultReviewed),
        });

        this.setAppLoading(false);

        if (result) {
          this.toast('Critical results reviewed saved!', false);
        } else {
          this.toast('error saving critical results reviewed', true);
        }
      }
    } else {
      return;
    }
  }

  /**
   * Deletes a biomarker result
   */
  public async deleteBiomarkerResult(biomakerResult) {
    const result = await dispatchDeleteBiomarkerResult(this.$store, {
      biomarkerResultId: biomakerResult.id,
    });
    if (result) {
      const temp = this.cloneList(
        this.assignedBiomarkerResults.filter((el) => el.id !== biomakerResult.id),
      );
      this.assignedBiomarkerResults = temp;
    }
  }
}
