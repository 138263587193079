var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":_vm.cancerDetected === null ? 500 : 700},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"pa-3 mt-4 mb-4 mx-3",attrs:{"color":"primary","dark":"","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("add_circle_outline")]),_vm._v(" Create Grail Test ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{class:{
        'header--text': true,
        'display-1': _vm.cancerDetected === null,
        'h2': _vm.cancerDetected || !_vm.cancerDetected
      }},[_vm._v("Create Grail Test "+_vm._s(_vm.cancerDetected ? '- Cancer Detected' : _vm.cancerDetected === false ? '- Cancer not Detected' : ''))])]),_c('v-card-text',[(_vm.cancerDetected === null)?[_c('h3',[_vm._v("Cancer detected?")])]:[(_vm.cancerDetected)?void 0:_vm._e(),(!_vm.cancerDetected)?_c('v-data-table',{staticClass:"elevation-1 my-3",attrs:{"headers":_vm.cancerNotDetectedHeader,"dense":"","items":_vm.grailResults,"items-per-page":-1}}):_vm._e(),(_vm.cancerDetected)?_c('v-data-table',{staticClass:"elevation-1 my-3",attrs:{"headers":_vm.cancerDetectedHeadr,"items":_vm.grailResults,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.testResult",fn:function(ref){
      var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"value":item.testResult ? item.testResult.toLowerCase() === 'positive' : false,"disabled":!_vm.cancerDetected},on:{"click":function($event){return _vm.changeResult(item)}}})]}}],null,false,1718083538)}):_vm._e(),_c('v-text-field',{attrs:{"label":"Collection site","data-vv-name":"collectionSite"},model:{value:(_vm.collectionSite),callback:function ($$v) {_vm.collectionSite=$$v},expression:"collectionSite"}}),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.errors.first('collectionSite')))]),_c('v-autocomplete',{attrs:{"items":_vm.physiciansList,"label":"Ordering Physician","chips":"","persistent-hint":""},model:{value:(_vm.assignedPyhsician),callback:function ($$v) {_vm.assignedPyhsician=$$v},expression:"assignedPyhsician"}}),_c('DataPicker',{attrs:{"dateProp":_vm.dateOfService,"dataPickerLabel":"Date of service"},on:{"dateChanged":function (date) { return _vm.dateOfService = date; }}})]],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function (){ return _vm.dialog = false; }}},[_vm._v("Cancel")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.cancerDetected === null),expression:"cancerDetected === null"}],attrs:{"color":"green","disabled":!_vm.noButtonEnabled},on:{"click":function () { return _vm.cancerDetected = false; }}},[_vm._v(" No ")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.cancerDetected === null),expression:"cancerDetected === null"}],attrs:{"depressed":"","color":"error","disabled":!_vm.yesButtonEnabled},on:{"click":function () { return _vm.cancerDetected = true; }}},[_vm._v("Yes ")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.cancerDetected !== null),expression:"cancerDetected !== null"}],attrs:{"depressed":"","disabled":!_vm.submitEnabled,"color":"primary"},on:{"click":_vm.submitClicked}},[_vm._v("Submit ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }