



































































import {Component, Prop} from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import {ITableRequisitionDocuments} from '@/interfaces/documents';
import UploadDocumentModal from '@/components/modals/UploadDocumentModal.vue';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import {IVisitDocument} from '@/interfaces/visits';

@Component({components: {UploadDocumentModal, ConfirmationModal}})
export default class DocumentsTable extends AppComponent {
  @Prop({default: true}) public showActions: boolean;
  @Prop({default: true}) public showSearch: boolean;
  @Prop({default: false}) public yieldActions: boolean;
  @Prop({default: 10}) public itemsPerPage: number;
  @Prop({default: () => []}) public documents: IVisitDocument[];
  @Prop({default: false}) public tableLoading: boolean;
  @Prop({default: false}) public changingVisibility: boolean;

  public search = '';
  public docStatus = 'preliminary';
  public docStatuses = [
    {text: 'Preliminary', value: 'preliminary'},
    {
      text: 'Final',
      value: 'final',
    },
    {text: 'Corrected', value: 'corrected'},
  ];

  public headers = [
    {
      text: 'Document Name',
      sortable: true,
      value: 'url',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
    },
  ];


  public documentIsValid(status: string) {
    return !status.includes('invalid');
  }

  public deleteDocument(document: IVisitDocument) {
    this.$emit('delete-document', document);
  }

  public createVisitDocument(document: IVisitDocument) {
    document.docStatus = this.docStatus as IVisitDocument['docStatus'];
    document.docType = 'DiagnosticReport';
    this.$emit('create-document', document);
  }
}
