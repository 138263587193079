



















































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import DataPicker from '@/components/DataPicker.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { IBiomarkerResult } from '@/interfaces/biomarkerResults';
import { IBiomarker } from '@/interfaces/biomarkers';
import { dispatchGetPackages, dispatchGetUsers } from '@/store/crud/actions';
import { IPanel } from '@/interfaces/panels';

@Component({ components: { DataPicker } })
export default class CreateGrailResultModal extends AppComponent {
  @Prop({ required: true, type: Boolean }) public show: boolean;

  public cancerDetected: boolean | null = null; // in this case, null is used as a third option
  public dialog = false;
  public collectionSite: string = '';
  public assignedPyhsician: string = '';
  public dateOfService: string = '';
  public grailTestBiomarkers: IBiomarker[] = [];
  public grailResults: IBiomarkerResult[] = [];

  public cancerDetectedHeadr: any[] = [
    { text: 'Biomarker', value: 'biomarkerName' },
    { text: 'Quest code', value: 'questBiomarkerId' },
    { text: 'Positive?', align: 'center', value: 'testResult' },
  ];

  public cancerNotDetectedHeader: any[] = [
    { text: 'Biomarker', value: 'biomarkerName' },
    { text: 'Quest code', value: 'questBiomarkerId' },
    { text: 'Result', align: 'center', value: 'testResult' },
  ];


  @Watch('dialog')
  public dialogDisplayChanged(val: boolean) {
    if (val) {
      this.initView();
    }
  }

  @Watch('grailTestBiomarkers')
  public biomarkersChanged(biomarkers: IBiomarker[]) {
    this.grailResults = biomarkers.map((biomarker) => {
      return {
        biomarkerName: biomarker.name,
        biomarkerId: biomarker.id,
        questBiomarkerId: biomarker.questBiomarkerCode,
        testResult: 'NEGATIVE',
      } as IBiomarkerResult;
    });
  }

  /**
   * Toggles the test result from negative to positive or vice versa
   */
  public changeResult(item) {
    if (item.testResult) {
      if (item.testResult.toLowerCase() === 'positive') {
        item.testResult = 'NEGATIVE';
      } else {
        item.testResult = 'POSITIVE';
      }
    }
  }

  /*
    Creates a biomarker result for each biomarker in the grail test.
    and inject the values from the form
  */
  public submitClicked() {
    const biomarkerResultsToCreate: IBiomarkerResult [] = [];
    this.grailResults.forEach((biomarkerResult) => {
      const payload = {
        biomarkerName: biomarkerResult.biomarkerName,
        questBiomarkerId: biomarkerResult.questBiomarkerId,
        biomarkerId: biomarkerResult.biomarkerId,
        testResult: biomarkerResult.testResult,
        collectionSite: this.collectionSite,
        orderingPhysicianId: this.assignedPyhsician,
        testResultOutOfRange: biomarkerResult.testResult
          && biomarkerResult.testResult.toLowerCase() === 'positive' ? true : false,
        dateOfService: this.dateOfService,
      } as IBiomarkerResult;

      // remove keys from the payload that were not filled in the form
      if (!this.collectionSite || this.collectionSite.length === 0) { delete payload.collectionSite; }
      if (!this.assignedPyhsician || this.assignedPyhsician.length === 0) { delete payload.orderingPhysicianId; }
      if (!this.dateOfService || this.dateOfService.length === 0) { delete payload.dateOfService; }

      biomarkerResultsToCreate.push(payload);
    } );
    this.$emit('grail-test:created', biomarkerResultsToCreate);
    this.dialog = false;
  }

  /**
   * Check if the submit button should be enabled
   * if cancerDetected is null (not yes or no selection made yet), the submit buttons is disabled
   * and also hidden.
   * If cancerDetected is true, the submit button will be enabled if there is at least
   * one biomarker POSITIVE flagged
   */
  public get submitEnabled(): boolean {
    // checking also with false since cancerDetected === null means no selection
    if (this.cancerDetected) {
      if (this.grailResults && this.grailResults.length > 0) {
        if (this.grailResults.some((biomarkerResult) => {
          return biomarkerResult.testResult === 'POSITIVE';
        }) ) {
          return true;
        }
      }
      return false;
    } else if (this.cancerDetected === false) {
      return true;
    }
    return false;
  }

  public get yesButtonEnabled(): boolean {
    return this.cancerDetected === null || !this.cancerDetected;
  }

  public get noButtonEnabled(): boolean {
    return this.cancerDetected === null || this.cancerDetected;
  }


  public async initView() {
    this.setAppLoading(true);
    this.reset();
    await dispatchGetPackages(this.$store);
    await dispatchGetUsers(this.$store);
    this.grailTestBiomarkers = this.getGrailTestBiomarkers();
    this.setAppLoading(false);
  }

  /**
   * Get all the biomarkers from the grail test package.
   * The package has a panel and the panels have the biomarkers
   * We search the package by name containing "grail"
   */
  public getGrailTestBiomarkers() {
    const grailTestBiomarkers: IBiomarker [] = [];

    // get the grail test package
    const grailTestPackage = this.packages.find((p) => p.name && p.name.toLowerCase().includes('grail'));
    // get all the biomarkeres from the panels of the package
    if (grailTestPackage && grailTestPackage.panels && grailTestPackage.panels.length > 0) {
      (grailTestPackage.panels as IPanel []).forEach((panel: IPanel) => {
        if (panel.biomarkers && panel.biomarkers.length > 0) {
          grailTestBiomarkers.push(...panel.biomarkers as IBiomarker []);
        }
      });
    }
    return grailTestBiomarkers;
  }


  /**
   * on mounted hook
   * it sets the data from the store to the component and resets the form
   */
  public async mounted() {
    this.reset();
  }

  /**
   * it reset the form
   */
  public reset() {
    this.cancerDetected = null;
    this.collectionSite = '';
    this.assignedPyhsician = '';
    this.dateOfService = '';
    this.grailResults = [];
  }

}
